<template>
  <div :class="['reviewRooms', !isEdit ? 'hashEdit' : '']" v-bind="getDataCities && getDataRooms && getDataOffices && data" v-bind:key="resetKey">
    <form v-on:submit.prevent="sendReview()" :id="data.userNameModel" enctype="multipart/form-data">
      <div class="reviewRooms__usersData">
        <div class="reviewRooms__titles">Información Usuario</div>
        <div class="reviewRooms__date">
          {{ dateForm(date) }}
        </div>
        <ul class="reviewRooms__ul">
          <li class="reviewRooms__li">
            <label class="reviewRooms__label">{{ reviewData.userNameMonitor }} *</label>
            <input class="reviewRooms__values" name="userNameMonitor" :value="data.userNameMonitor" />
          </li>
          <li class="reviewRooms__li">
            <label class="reviewRooms__label">{{ reviewData.userNameModel }} *</label>
            <input class="reviewRooms__values" name="userNameModel" :value="data.userNameModel" />
          </li>
          <li class="reviewRooms__li">
            <label class="reviewRooms__label">{{ reviewData.city }} *</label>
            <div class="reviewRooms__groupSelect">
              <select class="reviewRooms__select" @change="onChange($event)" :disabled="selectCity">
                <option>Elige una ciudad</option>
                <option v-for="(city, idx) in cities" :key="`${idx}${city}`" :value="city.city" :selected="city.city === data.city">
                  {{ city.city }}
                </option>
              </select>
              <button class="reviewRooms__edit active" :id="`city-${data.id}`" type="button" @click="disableSelect('city'), addClass(`city-${data.id}`)">
                <i class="fas fa-pencil-alt"></i>
              </button>
            </div>
          </li>
          <li class="reviewRooms__li">
            <label class="reviewRooms__label">{{ reviewData.turnOfTransmition }} *</label>
            <div class="reviewRooms__groupSelect">
              <select class="reviewRooms__select" @change="onChangeTurn($event)" :disabled="selectTurn">
                <option
                  v-for="(turn, idt) in TurnOfTransmición"
                  :key="`${idt}${turn}`"
                  :value="turn"
                  :selected="pushdate ? turn === pushdate : data.typeForm === 'isEdit' ? turn === data.turnOfTransmition : turn === dateConvert(data.turnOfTransmition)"
                >
                  {{ turn }}
                </option>
              </select>
              <button class="reviewRooms__edit active" type="button" :id="`turn-${data.id}`" @click="disableSelect('turn'), showEdit(), addClass(`turn-${data.id}`)">
                <i class="fas fa-pencil-alt"></i>
              </button>
            </div>
            <div v-if="showEditTurn">
              <div class="reviewRooms__others">
                <label class="reviewRooms__label">Otro horario</label>
                <input class="reviewRooms__hour" type="number" min="0" max="12" v-model="hourOne" />
                <select class="reviewRooms__hourSelect" v-model="typeHourOne">
                  <option value="am">AM</option>
                  <option value="pm">PM</option>
                </select>
                <span class="reviewRooms__script">-</span>
                <input class="reviewRooms__hour" type="number" min="0" max="12" v-model="hourTwo" />
                <select class="reviewRooms__hourSelect" v-model="typeHourTwo">
                  <option value="am">AM</option>
                  <option value="pm">PM</option>
                </select>
                <button class="reviewRooms__edit reviewRooms__edit--plus" type="button" @click="sendTurn()">
                  <i class="fas fa-arrow-right"></i>
                </button>
                <div v-if="stateVal" class="reviewRooms__validationDate">
                  Ingrese una fecha valida
                </div>
              </div>
            </div>
          </li>
          <li class="reviewRooms__li">
            <label class="reviewRooms__label">{{ reviewData.sede }} *</label>
            <div class="reviewRooms__groupSelect">
              <select class="reviewRooms__select" @change="onChangeOffice($event)" :disabled="selectOffice">
                <option :selected="choose">Elige una sede</option>
                <option v-for="(office, ids) in offices" :key="`${ids}${office}`" :value="office.office" :selected="office.office === officeData">
                  {{ office.office }}
                </option>
              </select>
              <button class="reviewRooms__edit" type="button" :id="`office-${data.id}`" @click="disableSelect('office'), addClass(`office-${data.id}`)">
                <i class="fas fa-pencil-alt"></i>
              </button>
            </div>
          </li>
          <li class="reviewRooms__li">
            <label class="reviewRooms__label">{{ reviewData.room }} *</label>
            <div class="reviewRooms__groupSelect">
              <select class="reviewRooms__select" @change="onChangeRoom($event)" :disabled="selectRoom">
                <option :selected="choose">Elige una habitación</option>
                <option v-for="(room, idr) in rooms" :key="`${idr}${room}`" :value="room.room" :selected="room.room === roomData">
                  {{ room.room }}
                </option>
              </select>
              <button class="reviewRooms__edit active" type="button" :id="`room-${data.id}`" @click="disableSelect('room'), addClass(`room-${data.id}`)">
                <i class="fas fa-pencil-alt"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="reviewRooms__usersData">
        <div class="reviewRooms__titles">Revisión de la habitación</div>
        <div class="reviewRooms__info">
          <span class="reviewRooms__msg">Responde a continuación la siguiente encuesta:</span>
          <div class="reviewRooms__confirm">
            <span class="reviewRooms__yes">Si</span>
            <span class="reviewRooms__not">No</span>
          </div>
        </div>
        <ul class="reviewRooms__ul" v-for="(item, index) in review" :key="`${item}${index}`">
          <li class="reviewRooms__li--check">
            <label class="reviewRooms__label--check">{{ item }} *</label>
            <div class="reviewRooms__radios">
              <input class="mycheck circle reviewRooms__cirYes" type="radio" :name="`${index}`" value="yes" :checked="data.typeForm === 'isEdit' ? bools[index] : true" />
              <input class="mycheck circle reviewRooms__cirNot" type="radio" :name="`${index}`" value="not" :checked="data.typeForm === 'isEdit' ? !bools[index] : false" />
            </div>
          </li>
        </ul>
      </div>
      <div class="reviewRooms__usersData">
        <label class="reviewRooms__titles">Revisión Habitaciones</label>

        <div class="reviewRooms__inputFiles">
          <input class="reviewRooms__files" type="file" id="reviewRoomsPhotos" @change="photoValues" accept="image/*" multiple placeholder="Fotos de tipo (png, jpge, jpg...)" />
          <div class="reviewRooms__position">
            <input class="reviewRooms__files reviewRooms__files--camera" type="file" id="reviewRoomsPhotosCamera" @change="photoValuesCamera" capture="camera" accept="image/*" />
            <label for="reviewRoomsPhotosCamera">
              <i class="fas fa-camera-retro reviewRooms__camera"></i>
            </label>
          </div>
        </div>
        <div class="reviewRooms__descriptions" v-for="(item, idx) in previewPhotos" :key="idx">
          <img class="reviewRooms__imgPreview" :src="item" :alt="`previewPhoto${idx}`" />
          <input
            :id="idx"
            class="reviewRooms__descriptionsInput"
            :name="`createreviewdescription${idx}`"
            type="text"
            :value="data.typeForm === 'isEdit' ? descriptions[idx] : desCreate[idx]"
            @input="onChangeDescription(idx, $event)"
            :placeholder="`Descripción foto ${idx + 1}`"
          />
          <div class="error" :id="`idx${idx}`"></div>
          <button class="reviewRooms__btnDelete" @click.prevent="onDeletePhoto(idx)"><i class="fa fa-trash"></i></button>
        </div>
      </div>
      <div class="reviewRooms__usersData">
        <div class="reviewRooms__mantenience">
          <label class="reviewRooms__titles">
            Mantenimiento General
          </label>
          <div>
            <span class="reviewRooms__required" v-html="valueText ? 'Requerido ' : 'No requerido '"></span>
            <ToggleInput @isChecked="getModel" :checked="statusMantenienceRoom ? statusMantenienceRoom : false" />
          </div>
        </div>
        <div v-show="isMantenience">
          <div class="reviewRooms__inputFiles">
            <input class="reviewRooms__files" type="file" id="reviewRoomsMaintenance" @change="photoValuesMaintenance" accept="image/*" multiple placeholder="Fotos de tipo (png, jpge, jpg...)" />
            <div class="reviewRooms__position">
              <input class="reviewRooms__files reviewRooms__files--camera" type="file" id="reviewRoomsMaintenanceCamera" @change="photoValuesCameraMaintenance" capture="camera" accept="image/*" />
              <label for="reviewRoomsMaintenanceCamera">
                <i class="fas fa-camera-retro reviewRooms__camera"></i>
              </label>
            </div>
          </div>
          <div class="reviewRooms__descriptions" v-for="(item, idm) in previewPhotosMantenience" :key="idm">
            <img class="reviewRooms__imgPreview" :src="item" :alt="`previewPhotoMantenience${idm}`" />
            <input
              :id="`mant${idm}`"
              class="reviewRooms__descriptionsInput"
              :name="`descriptionmaintenance${idm}`"
              type="text"
              @input="onChangeDescriptionMantenience(idm, $event)"
              :value="data.typeForm === 'isEdit' ? descriptionsMantenience[idm] : desCreateMant[idm]"
              :placeholder="`Descripción foto ${idm + 1}`"
            />
            <div class="error" :id="`mantenience${idm}`"></div>
            <button class="reviewRooms__btnDelete" @click.prevent="onDeletePhotoMantenience(idm)"><i class="fa fa-trash"></i></button>
          </div>
        </div>
      </div>
      <div class="reviewRooms__usersData">
        <div class="reviewRooms__disable">
          <label class="reviewRooms__titles">
            Estado habitación
          </label>
          <div>
            <span class="reviewRooms__disableTitle">{{ !disableRoom ? "Deshabilitada" : "Habilitada" }}</span>
            <ToggleInput @isChecked="getDisable" :checked="data.typeForm === 'isEdit' ? (data.isEnable ? false : true) : false" />
          </div>
        </div>
      </div>
      <div class="reviewRooms__usersData">
        <ul class="reviewRooms__ul">
          <li class="reviewRooms__li">
            <label class="reviewRooms__titles">{{ reviewData.observation }}:</label>
            <div class="reviewRooms__textarea" contenteditable="true" :id="`text-${data.userNameModel}`" v-html="data.typeForm === 'isEdit' ? data.observations : ''" scrollable="true"></div>
          </li>
        </ul>
      </div>
      <div class="reviewRooms__btns">
        <button class="reviewRooms__saveReview" type="submit" v-if="isEdit">
          {{ msgBtn }}
        </button>
        <button class="reviewRooms__cancel" @click="cancelForm" v-if="isEdit">
          Cancelar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import CitySelect from "@/components/forms/select/CitySelect.vue";
  import ToggleInput from "@/components/input/ToggleInput.vue";
  import { dateLocaleFormatEs } from "@/js/utils";
  import { mapGetters } from "vuex";
  import Swal from "sweetalert2";
  import swal from "@/js/swalAlerts";
  import axios from "@/api/axios";
  import Iconic from "@/plugins/iconic/iconic.vue";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      bools: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        optionFormat: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        },
        resetKey: 0,
        isEdit: true,
        isMantenience: false,
        pushdate: null,
        selectCity: true,
        selectOffice: true,
        selectRoom: true,
        selectTurn: true,
        valueText: this.data.statusMantenienceRoom,
        cities: [],
        rooms: [],
        offices: [],
        turns: this.$global.dictionary.TurnOfTransmición,
        review: this.$global.dictionary.review,
        reviewData: this.$global.dictionary.reviewData,
        date: Date(),
        turnTransmition: "",
        hourOne: null,
        typeHourOne: null,
        hourTwo: null,
        typeHourTwo: null,
        showEditTurn: false,
        cityAppend: this.data.city,
        officeAppend: this.data.sede,
        roomAppend: this.data.room,
        descriptions: this.data.descriptionPhotos || [],
        descriptionsMantenience: this.data.descriptionMantenience || [],
        turnAppend: null,
        stateVal: false,
        officeData: null,
        roomData: null,
        city: this.data.city,
        office: this.data.sede,
        totalPhotos: null,
        msgBtn: "Enviar Revisión",
        photosReview: [],
        previewPhotos: [],
        photosReviewMantenience: [],
        previewPhotosMantenience: [],
        statusMantenienceRoom: this.data.statusMantenienceRoom,
        TurnOfTransmición: ["06:00 AM - 02:00 PM", "02:00 PM - 10:00 PM", "10:00 PM - 06:00 AM"],
        isValidForm: {},
        isValidFormMantenience: {},
        choose: false,
        desCreate: [],
        desCreateMant: [],
        disableRoom: true,
      };
    },

    computed: {
      ...mapGetters("cities", { getCities: "getData" }),
      ...mapGetters("rooms", { getRooms: "getData" }),
      ...mapGetters("offices", { getOffices: "getData" }),
      ...mapGetters("user", ["getRole", "getSpecialFunctions"]),
      getDataCities() {
        if (this.getCities) {
          return (this.cities = this.getCities.map((city) => ({ city: city.cityName })));
        }
        return [];
      },
      getDataOffices() {
        if (this.getOffices && this.city) {
          return (this.offices = this.getOffices.filter((office) => office.cityName === this.city).map((office) => ({ office: office.officeName })));
        }
        return [];
      },
      getDataRooms() {
        if (this.getRooms && this.office) {
          return (this.rooms = this.getRooms.filter((room) => room.officeName === this.office).map((room) => ({ room: room.roomName })));
        }
        return [];
      },
      getRoleConect() {
        return this.getRole;
      },
      getRoleSpecial() {
        return this.getSpecialFunctions;
      },
    },
    components: { CitySelect, ToggleInput, Iconic },
    methods: {
      cancelForm() {
        if (this.data.typeForm === "isEdit") {
          this.$root.$emit("closeReviewEdit", this.data.id);
        } else {
          this.$root.$emit("closeReview");
        }
      },
      getModel(model) {
        this.isMantenience = model;
        this.valueText = model;
      },
      getDisable(model) {
        this.disableRoom = !model;
      },
      validImage(file) {
        const fileExts = /(\.(png|jpeg|jpg))$/i;
        const isValid = fileExts.test(file);
        return isValid;
      },
      onDeletePhoto(id) {
        this.previewPhotos.splice(id, 1);
        this.photosReview.splice(id, 1);
        if (this.data.typeForm === "isEdit") {
          this.descriptions.splice(id, 1);
        } else {
          this.desCreate.splice(id, 1);
        }
      },
      onChangeDescription(id, value) {
        if (value.target.value) {
          this.descriptions.splice(id, 1, value.target.value);
          this.desCreate.splice(id, 1, value.target.value);
        }
      },
      onDeletePhotoMantenience(idm) {
        this.photosReviewMantenience.splice(idm, 1);
        this.previewPhotosMantenience.splice(idm, 1);
        if (this.data.typeForm === "isEdit") {
          this.descriptionsMantenience.splice(idm, 1);
        } else {
          this.desCreateMant.splice(idm, 1);
        }
      },
      onChangeDescriptionMantenience(idm, value) {
        if (value.target.value) {
          this.descriptionsMantenience.splice(idm, 1, value.target.value);
          this.desCreateMant.splice(idm, 1, value.target.value);
        }
      },
      photoValues() {
        let values = document.getElementById("reviewRoomsPhotos").files;
        this.pushPhoto(values);
      },
      photoValuesCamera() {
        let values = document.getElementById("reviewRoomsPhotosCamera").files;
        this.pushPhoto(values);
      },
      photoValuesMaintenance() {
        let values = document.getElementById("reviewRoomsMaintenance").files;
        this.pushPhotoMantenience(values);
      },
      photoValuesCameraMaintenance() {
        let values = document.getElementById("reviewRoomsMaintenanceCamera").files;
        this.pushPhotoMantenience(values);
      },
      pushPhoto(values) {
        const validImg = this.validImage(values["0"].name);
        if (validImg) {
          for (const i of values) {
            this.previewPhotos.push(URL.createObjectURL(i));
            this.photosReview.push(i);
          }
        } else {
          Swal.fire({
            text: "La imagen debe ser formato png, jpg, jpeg.",
            icon: "error",
            iconColor: "#bd0909",
            confirmButtonColor: "#bd0909",
          });
        }
      },
      pushPhotoMantenience(values) {
        const validImg = this.validImage(values["0"].name);
        if (validImg) {
          for (const i of values) {
            this.previewPhotosMantenience.push(URL.createObjectURL(i));
            this.photosReviewMantenience.push(i);
          }
        } else {
          Swal.fire({
            text: "La imagen debe ser formato png, jpg, jpeg.",
            icon: "error",
            iconColor: "#bd0909",
            confirmButtonColor: "#bd0909",
          });
        }
      },
      addClass(id) {
        document.getElementById(id).classList.toggle("activeBtn");
      },
      onChange(value) {
        this.cityAppend = value.target.value;
        this.city = value.target.value;
        this.choose = true;
        this.officeAppend = null;
        this.officeData = null;
        this.roomAppend = null;
        this.getDataOffices;
        this.getDataRooms;
        if (this.selectOffice) {
          this.disableSelect("office");
          this.addClass(`office-${this.data.id}`);
        }
      },
      onChangeOffice(value) {
        this.officeAppend = value.target.value;
        this.office = value.target.value;
        this.choose = false;
        this.roomData = null;
        this.roomAppend = null;
        this.getDataRooms;
        if (this.selectRoom) {
          this.disableSelect("room");
          this.addClass(`room-${this.data.id}`);
        }
      },
      onChangeRoom(value) {
        this.roomAppend = value.target.value;
        this.getDataRooms;
        this.choose = false;
      },
      onChangeTurn(value) {
        this.turnAppend = value.target.value;
      },
      sendTurn() {
        this.pushdate = `${this.hourOne}${this.typeHourOne} - ${this.hourTwo}${this.typeHourTwo}`;
        this.initTurn();
        this.validatorForm();
        if (!this.stateVal) {
          this.TurnOfTransmición.push(this.pushdate);
          this.turnAppend = this.pushdate;
          this.disableSelect("turn");
          this.showEdit();
          this.addClass(`turn-${this.data.id}`);
        }
      },
      showEdit() {
        this.showEditTurn = !this.showEditTurn;
      },
      initTurn() {
        this.TurnOfTransmición = ["06:00 AM - 02:00 PM", "02:00 PM - 10:00 PM", "10:00 PM - 06:00 AM"];
      },
      initHourEdit() {
        this.hourOne = null;
        this.typeHourOne = null;
        this.hourTwo = null;
        this.typeHourTwo = null;
        this.pushdate = null;
      },
      dateConvert(date) {
        if (date.name && date.schedule) {
          return date.schedule;
        }
        const hour = new Date(date);
        const hours = hour.getHours();
        if (hours >= 6 && hours < 14) {
          this.turnTransmition = this.turns.morning;
        } else if (hours >= 14 && hours < 22) {
          this.turnTransmition = this.turns.late;
        } else if ((hours >= 22 && hours < 24) || (hours >= 0 && hours < 6)) {
          this.turnTransmition = this.turns.night;
        } else {
          this.turnTransmition = "";
        }
        return this.turnTransmition;
      },
      dataSending: function() {
        Swal.fire({
          title: "Creando",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
      },
      validatorForm() {
        if (!this.typeHourTwo) {
          return (this.stateVal = true);
        }
        if (!this.typeHourOne) {
          return (this.stateVal = true);
        }
        if (!this.hourOne && !this.hourTwo) {
          return (this.stateVal = true);
        }
        if (this.hourOne <= 12 && this.hourOne > 0 && this.hourTwo <= 12 && this.hourTwo > 0) {
          return (this.stateVal = false);
        }
        return (this.stateVal = true);
      },
      validSelects() {
        if (!this.cityAppend) return false;
        if (!this.officeAppend) return false;
        if (!this.roomAppend) return false;
        if (!this.turnAppend) return false;
        return true;
      },
      sendReview() {
        for (let index = 0; index < this.previewPhotos.length; index++) {
          if (document.getElementById(index).value === "") {
            document.getElementById(`idx${index}`).innerText = "falta";
            this.isValidForm[index] = false;
          } else {
            document.getElementById(`idx${index}`).innerText = "";
            this.isValidForm[index] = true;
          }
        }
        const isValidForm = Object.values(this.isValidForm);
        for (const foto of isValidForm) {
          if (!foto) {
            Swal.fire({
              text: "Ingrese las descripciones por favor.",
              icon: "error",
              iconColor: "#bd0909",
              confirmButtonColor: "#bd0909",
            });
            return null;
          }
        }
        for (let ind = 0; ind < this.previewPhotosMantenience.length; ind++) {
          if (document.getElementById(`mant${ind}`).value === "") {
            document.getElementById(`mantenience${ind}`).innerText = "falta.";
            this.isValidFormMantenience[ind] = false;
          } else {
            document.getElementById(`mantenience${ind}`).innerText = "";
            this.isValidFormMantenience[ind] = true;
          }
        }
        const isValidFormMantenience = Object.values(this.isValidFormMantenience);
        for (const foto of isValidFormMantenience) {
          if (!foto) {
            Swal.fire({
              text: "Ingrese las descripciones en la sección de mantenimiento por favor.",
              icon: "error",
              iconColor: "#bd0909",
              confirmButtonColor: "#bd0909",
            });
            return null;
          }
        }
        if (!this.validSelects()) {
          Swal.fire({
            text: "Ingrese todos los campos en información de usuario.",
            icon: "error",
            iconColor: "#bd0909",
            confirmButtonColor: "#bd0909",
          });
          return;
        }
        if (this.stateVal) {
          Swal.fire({
            text: "Se encontraron errores en el formulario.",
            icon: "error",
            iconColor: "#bd0909",
            confirmButtonColor: "#bd0909",
          });
          return;
        }
        const textReview = document.getElementById(`text-${this.data.userNameModel}`).innerHTML;
        const _data = new FormData(document.getElementById(`${this.data.userNameModel}`));
        _data.append("id", this.data.id);
        _data.append("observation", textReview);
        _data.append("city", this.cityAppend);
        _data.append("office", this.officeAppend);
        _data.append("room", this.roomAppend);
        _data.append("turnOfTransmition", this.turnAppend);
        _data.append("statusMantenienceRoom", this.isMantenience);
        _data.append("disableRoom", this.disableRoom);

        for (let i = 0; i < this.photosReview.length; i++) {
          _data.append(`imagencreatereview${i}`, this.photosReview[i]);
        }
        for (let i = 0; i < this.photosReviewMantenience.length; i++) {
          _data.append(`photosmantenience${i}`, this.photosReviewMantenience[i]);
        }
        if (!(this.data.typeForm === "isEdit")) {
          _data.append("update", true);
        }
        swal
          .dataSendConfirm({
            message: "¿Estás seguro de enviar el formulario?",
            title: "",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              let res, msgRes;
              if (this.data.typeForm === "isEdit") {
                this.dataSending();
                res = await axios.put(`/reviews`, _data);
                Swal.close();
                msgRes = res.data.success;
              } else {
                this.dataSending();
                res = await axios.post("/reviews", _data);
                Swal.close();
                msgRes = "Revisión enviada correctamente";
              }
              if (res.data.success) {
                Swal.fire({
                  text: msgRes,
                  icon: "success",
                  confirmButtonColor: "#bd0909",
                });
                if (this.data.typeForm === "isEdit") {
                  this.$root.$emit("closeReviewEdit", this.data.id);
                  this.$root.$emit("updateReviewRefresh", res.data.update);
                } else {
                  this.resetKey++;
                  this.$root.$emit("closeReview");
                  this.$root.$emit("postReviewRefresh", res.data.userUpdate);
                }
                this.initTurn();
                this.initHourEdit();
              } else {
                Swal.fire({
                  title: "La revisión no fue procesada",
                  text: res.data.error,
                  icon: "error",
                  iconColor: "#bd0909",
                  confirmButtonColor: "#bd0909",
                });
              }
            }
          });
      },
      dateForm(date) {
        return dateLocaleFormatEs(date);
      },
      disableSelect(type) {
        switch (type) {
          case "city":
            this.selectCity = !this.selectCity;
            break;
          case "office":
            this.selectOffice = !this.selectOffice;
            break;
          case "room":
            this.selectRoom = !this.selectRoom;
            break;
          case "turn":
            this.selectTurn = !this.selectTurn;
            break;
        }
      },
    },
    beforeMount() {
      this.officeData = this.data.sede;
      this.roomData = this.data.room;
    },
    mounted() {
      if (this.statusMantenienceRoom) {
        this.isMantenience = true;
      }
      if (this.data.typeForm === "isEdit") {
        this.previewPhotos.push(...this.data.photosReview);
        this.photosReview.push(...this.data.photosReview);
        this.photosReviewMantenience.push(...this.data.photosReviewMantenience);
        this.previewPhotosMantenience.push(...this.data.photosReviewMantenience);
        this.msgBtn = "Guardar Revisión";
      }
      this.turnAppend = this.dateConvert(this.data.turnOfTransmition);
      if (this.data.turnOfTransmition && this.data.typeForm === "isEdit") {
        if (!this.TurnOfTransmición.includes(this.data.turnOfTransmition)) {
          this.pushdate = this.data.turnOfTransmition;
          this.turnAppend = this.pushdate;
          this.TurnOfTransmición.push(this.pushdate);
        }
        this.turnAppend = this.data.turnOfTransmition;
      }
      if (this.data.typeForm === "isEdit") {
        if (this.getRoleConect !== "coordinator" && this.getRoleConect !== "superadmin") {
          this.isEdit = false;
        }
        if (this.getRoleConect === "coordinator") {
          if (!this.getRoleSpecial) {
            this.isEdit = false;
          }
        }
        this.disableRoom = this.data.isEnable;
      }
    },
  };
</script>

<style lang="scss">
  .reviewRooms {
    .toggle-switch {
      background: $japanese_laurel;
    }
    text-align: left;
    max-width: 1440px;
    margin: $mpadding auto;
    font-family: $label-forms;
    &.hashEdit {
      position: relative;
      &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
        z-index: 20;
      }
    }
    .mycheck {
      width: 22px;
      height: 22px;
    }

    .error {
      color: $lipstick;
    }
    &__required {
      display: none;
    }
    &__mantenience {
      display: flex;
      justify-content: space-between;
    }
    &__inline {
      display: flex;
      > * {
        margin-right: 10px;
        flex: 1;
      }
    }
    &__btnDelete {
      @include Row();
      width: 34px;
      height: 34px;
      padding: 4px;
      border-radius: 6px;
      margin-left: 15px;
      margin-right: 5px;
      background-color: $primary_color;
      border: none;
      cursor: pointer;
      color: $white;
      justify-self: flex-end;
      &__focus {
        outline: none;
      }
    }
    &__inputFiles {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    &__files {
      font-size: 100%;
      width: 100%;
      border: 1px solid $gray-edward;
      border-radius: 5px;
      padding: $mpadding/2;
      margin-bottom: $mpadding;
      &::-webkit-file-upload-button {
        display: none;
      }
      &:active {
        background-color: $alto;
      }
      &:hover {
        cursor: pointer;
      }
      &::before {
        content: attr(placeholder);
        margin-right: $mpadding/2;
        font-weight: normal;
      }
      &--camera {
        width: 40px;
        background: $lipstick;
        border: none;
        color: $lipstick;
      }
    }
    &__position {
      position: relative;
      margin-left: 10px;
    }
    &__camera {
      position: absolute;
      font-size: 25px;
      color: $white;
      left: 7px;
      top: 6px;
    }
    &__descriptions {
      display: flex;
      width: 100%;
      margin-bottom: $mpadding/2;
      align-items: center;
    }
    &__descriptionsInput {
      width: 70%;
      padding: 5px;
      margin-left: 10px;
      border-radius: 5px;
      border: 1px solid $gray-edward;
      &:focus {
        outline: none;
      }
    }
    &__imgPreview {
      height: 47px;
      width: 47px;
      border-radius: 50%;
      overflow: hidden;
      background: $mercury;
      object-fit: cover;
    }
    &__disable {
      display: flex;
      padding-top: 5px;
      align-items: center;
      justify-content: space-between;
    }
    &__disableTitle {
      display: none;
      margin-right: 5px;
    }
    &__validationDate {
      color: $lipstick;
    }
    &__script {
      margin: 0 10px;
    }
    &__others {
      margin: $mpadding auto;
      padding: $mpadding;
      background-color: $white;
      border-radius: $mradius;
      box-shadow: $dshadow;
      color: $lipstick;
    }
    &__otherTurn {
      margin: 20px auto;
      color: $chicago;
    }
    &__hourSelect {
      width: 30px;
      height: 35px;
      border-radius: 5px;
      border: 1px solid $alto;
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.97);
      }
    }
    &__hour {
      margin-right: $mpadding;
      width: 30px;
      height: 35px;
      border-radius: 5px;
      border: 1px solid $alto;
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.97);
      }
    }
    &__cirNot {
      margin-left: 5px;
      &:checked {
        background-color: $black;
        border-color: $black;
        color: $white;
        &:checked:before,
        &:checked:before {
          content: "\00D7";
          font-size: 130%;
        }
      }
    }
    &__hours {
      margin-left: 10px;
      max-width: 125px;
      border-radius: 5px;
      padding-left: 5px;
      outline: none;
      border: 1px solid $alto;
    }

    &__usersData {
      margin-bottom: $mpadding;
      padding: $mpadding;
      background-color: $white;
      border-radius: $mradius;
      box-shadow: $dshadow;
    }
    &__titles {
      display: block;
      font-size: 1.5em;
      font-family: $sec_font;
      text-transform: uppercase;
      color: $lipstick;
      font-weight: bold;
    }
    &__date {
      margin-bottom: $mpadding;
    }
    &__info {
      display: flex;
      margin: $mpadding 0;
      justify-content: space-between;
    }
    &__msg {
      width: 80%;
      font-size: 1em;
    }
    &__yes {
      font-size: 1em;
      margin-left: 0;
    }
    &__not {
      font-size: 1em;
      margin-left: $mpadding;
    }
    &__confirm {
      font-weight: bold;
      padding-right: 10px;
    }
    &__ul {
      list-style: none;
    }
    &__li {
      padding: $mpadding/5;
      font-family: $first_font;
      text-align: left;
      &--check {
        display: flex;
        padding: $mpadding/2;
        margin: 5px 0;
        background-color: $HawkesBlue;
        justify-content: space-between;
      }
    }
    &__label {
      font-family: 1em;
      font-family: $first_font;
      display: block;
      font-weight: bold;
      &--check {
        width: 70%;
        margin-right: 5px;
        padding-right: 10px;
      }
    }
    &__radioNot {
      margin-left: 10px;
    }
    &__values {
      width: 100%;
      height: 35px;
      border-radius: 5px;
      pointer-events: none;
      border: none;
      color: $gray-edward;
    }
    &__groupSelect {
      display: flex;
    }
    &__select {
      width: 100%;
      height: 35px;
      border-radius: 5px;
      border: 1px solid $chicago;
      color: $chicago;
      &:focus {
        outline: none;
      }
    }
    &__edit {
      margin-left: 15px;
      padding: $mpadding/3 $mpadding/2;
      border-radius: 5px;
      background-color: $chicago;
      border: none;
      color: $white;
      &--plus {
        background-color: $lipstick;
      }
      &:hover {
        opacity: $opacity-button;
      }
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.97);
      }
    }
    &__textarea {
      padding: 2px 5px;
      border: 1px solid $alto;
      margin-top: 10px;
      border-radius: 5px;
      min-height: 100px;
      &:focus {
        outline: none;
      }
    }
    &__btns {
      display: flex;
      justify-content: center;
    }
    &__cancel,
    &__saveReview {
      display: flex;
      width: 140px;
      white-space: nowrap;
      justify-content: center;
      outline: none;
      text-decoration: none;
      border-radius: $border-radius-bootstrap;
      color: $white;
      border: none;
      background-color: $lipstick;
      padding: $mpadding/2 $mpadding;
      outline-style: none;
      &:hover {
        opacity: $opacity-button;
      }
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.97);
      }
    }
    &__cancel {
      margin-left: 15px;
      width: 110px;
      background-color: $gray-emperor;
    }
    &__radioYes {
      &:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: $gray-edward;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid $white;
      }
      &:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: $lipstick;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid $cosmos;
      }
    }
    &__radioNot {
      &:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: $gray-edward;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid $white;
      }
      &:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: $lipstick;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid $cosmos;
      }
    }
  }
  .activeBtn {
    background-color: $lipstick;
  }
  @media (min-width: $mobile_width) {
    .reviewRooms {
      .mycheck {
        width: 28px;
        height: 28px;
      }
      &__hour {
        width: auto;
      }
      &__hourSelect {
        width: auto;
      }
      &__descriptionsInput {
        width: 80%;
      }
      &__disableTitle {
        display: inline;
      }
      &__required {
        display: inline;
      }
    }
  }
  @media (min-width: $tablet_width) {
    .reviewRooms {
      &__descriptionsInput {
        width: 90%;
      }
    }
  }
  @media (min-width: $desktop_width) {
    .reviewRooms {
      &__position {
        display: none;
      }
    }
  }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    display: none;
  }
  // enviar un prop de typo objeto, minimo con la siguiente información.
  // reviewData: {
  //   userNameMonitor: usuario del monitor,
  //   turnOfTransmition: fecha de la ultima conexion de la modelo,
  //   userNameModel: nombre artistico de la modelo,
  //   city: ciudad de la modelo,
  //   sede: sede de la modelo,
  //   room: habitacion de la modelo,
  // },
  // confirmar que los campos sean compatibles con los de la base de datos.
</style>
