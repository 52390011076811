<template>
  <b-form-select :options="options" :value="value" @input="onIput" :disabled="disabled" :multiple="multiple" />
</template>
<script>
export default {
  props: {
    value: {
      type: [Object, String, Number, Array],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    multiple: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onIput(value) {
      this.$emit("input", value);
    },
  },
};
</script>
