<template>
  <api-select
    ref="reference"
    :options="options"
    :value="value"
    @input="onInput"
    :multiple="multiple"
    @blur.native="format()"
  />
</template>
<script>
import ApiSelect from "./ApiSelect.vue";
import {  mapState } from "vuex";

export default {
  data() {
    return {
      valueForm: null
    };
  },
  components: { ApiSelect },
  props: {
    value: {
      type: [Object, String, Number, Array],
      default: null
    },
    disable: Boolean,
    multiple: Boolean
  },
  computed: {
    ...mapState({
      options: state => {
        const data = state.cities.data.map(city => ({
          value: city.cityName,
          text: city.cityName
        }));
        return [{ value: null, text: "Seleccione una ciudad" }, ...data];
      }
    })
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
    format(e) {
      this.valueForm = event.srcElement.value;
      this.$emit("blur");
    }
  },
};
</script>
